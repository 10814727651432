<template>
  <div class="flex justify-between items-center">
    <component
      :is="page > 1 ? 'a' : 'button'"
      :href="page > 1 ? doGetPageLink(page - 1) : undefined"
      :disabled="page === 1"
      :title="
        $texts(
          'accessibility.paginationPreviousPage',
          'Zur vorherigen Seite gehen',
        )
      "
      class="button is-prev"
      @click.prevent="onClick"
    >
      <span class="mobile-only:sr-only">{{ $texts('back', 'Zurück') }}</span>
    </component>
    <div class="flex pagination-buttons gap-3 items-center">
      <a
        v-for="p in beginningPages"
        :key="'begin_' + p"
        :href="doGetPageLink(p)"
        :class="{
          'is-active': p === page,
        }"
        @click.prevent="onClick"
      >
        <span class="sr-only">
          {{ $texts('accessibility.paginationGoToPage', 'Weiter zur Seite') }}
        </span>
        {{ p }}
      </a>
      <div v-if="hasBeginningGap" aria-hidden="true">&hellip;</div>
      <a
        v-for="p in slidingWindowPages"
        :key="'sliding_' + p"
        :class="{
          'is-active': p === page,
        }"
        :href="doGetPageLink(p)"
        :aria-current="p === page ? undefined : 'date'"
        @click.prevent="onClick"
      >
        <span class="sr-only">{{ $texts('accessibility.page', 'Seite') }}</span>
        {{ p }}
      </a>

      <div v-if="hasEndingGap" aria-hidden="true">&hellip;</div>
      <a
        v-for="p in endingPages"
        :key="'ending_' + p"
        :class="{
          'is-active': p === page,
        }"
        :href="doGetPageLink(p)"
        @click.prevent="onClick"
      >
        {{ p }}
      </a>
    </div>
    <component
      :is="page < totalPages ? 'a' : 'button'"
      :href="page < totalPages ? doGetPageLink(page + 1) : undefined"
      :disabled="page > totalPages - 1"
      :title="
        $texts('accessibility.paginationNextPage', 'Zur nächsten Seite gehen')
      "
      class="button is-next"
      @click.prevent="onClick"
    >
      <span class="mobile-only:sr-only">{{
        $texts('continue', 'Weiter')
      }}</span>
    </component>
  </div>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'

const route = useRoute()
const router = useRouter()

function range(start: number, end: number) {
  const r: number[] = []

  if (typeof start !== 'number' || typeof end !== 'number') {
    return r
  }

  if (start > end) {
    const temp = start
    start = end
    end = temp
  }

  for (let i = start; i <= end; i++) {
    r.push(i)
  }

  return r
}

type GetPageLink = (page: number) => RouteLocationRaw

const defaultGetPageLink: GetPageLink = function (
  page: number,
): RouteLocationRaw {
  const query = {
    ...route.query,
  }
  if (page <= 1) {
    delete query.page
    return {
      name: route.name!,
      params: route.params,
      query,
    }
  }

  return {
    name: route.name!,
    params: route.params,
    query: {
      ...query,
      page,
    },
  }
}

const props = withDefaults(
  defineProps<{
    page?: number
    totalPages?: number
    nonSlidingSize?: number
    getPageLink?: GetPageLink
  }>(),
  {
    page: 0,
    totalPages: 0,
    nonSlidingSize: 5,
    getPageLink: undefined,
  },
)

const emit = defineEmits<{
  (e: 'changePage'): void
}>()

const isSliding = computed(() => props.totalPages > props.nonSlidingSize)

const slidingEndingSize = 1
const slidingWindowSize = 3

const beginningPages = computed(() =>
  range(1, isSliding.value ? slidingEndingSize : props.totalPages),
)

const lastBeginningPage = computed(
  () => beginningPages.value[beginningPages.value.length - 1],
)

const firstWindowPage = computed(() => slidingWindowPages.value[0])

const endingPages = computed(() => {
  if (!isSliding.value) {
    return []
  }

  return range(props.totalPages - slidingEndingSize + 1, props.totalPages)
})

const firstEndingPage = computed(() => endingPages.value[0])

const slidingWindowHalf = computed(() => {
  let half = slidingWindowSize / 2

  if (slidingWindowSize % 2 === 1) {
    half -= 0.5
  }

  return half
})

const slidingWindowPages = computed(() => {
  if (!isSliding.value) {
    return []
  }

  const startOffset = lastBeginningPage.value + slidingWindowHalf.value
  const endOffset = firstEndingPage.value - slidingWindowHalf.value

  if (props.page <= startOffset) {
    return range(
      lastBeginningPage.value + 1,
      lastBeginningPage.value + slidingWindowSize,
    )
  }

  if (props.page > startOffset && props.page < endOffset) {
    let upperHalfForEvenWindowSizes = slidingWindowHalf.value
    if (slidingWindowSize % 2 === 0) {
      upperHalfForEvenWindowSizes /= 2
    }

    return range(
      -slidingWindowHalf.value + props.page,
      upperHalfForEvenWindowSizes + props.page,
    )
  }

  return range(
    firstEndingPage.value - slidingWindowSize,
    firstEndingPage.value - 1,
  )
})

const hasBeginningGap = computed(() => {
  if (!isSliding.value) {
    return false
  }

  return lastBeginningPage.value + 1 !== firstWindowPage.value
})

const lastWindowPage = computed(
  () => slidingWindowPages.value[slidingWindowPages.value.length - 1],
)

const hasEndingGap = computed(() => {
  if (!isSliding.value) {
    return false
  }

  return lastWindowPage.value + 1 !== firstEndingPage.value
})

function doGetPageLink(page: number): string {
  if (props.getPageLink) {
    return router.resolve(props.getPageLink(page)).href
  }

  return router.resolve(defaultGetPageLink(page)).href
}

async function onClick(e: MouseEvent) {
  if (!(e.target instanceof HTMLElement || e.target instanceof SVGElement)) {
    return
  }
  const link = e.target.closest('a')
  if (!link) {
    return
  }
  const href = link.getAttribute('href')
  if (!href) {
    return
  }
  await router.push(href)
  emit('changePage')
}
</script>

<style lang="postcss">
.pagination-buttons {
  > a,
  > div {
    @apply flex items-center justify-center font-medium rounded-full leading-none;
    @apply size-25 text-xs;
    @apply md:size-40 md:text-base;
  }

  > a {
    &:not(.is-active) {
      @apply hover:bg-gray-200;
    }
    &.is-active {
      @apply text-white bg-blue-900;
    }
  }
}
</style>
